import { getItem } from '../../../api/api-methods';
import { numberFormatMX } from '../../shared/helpers/dataUtils';
import { fetchGetGroupByStudent } from '../helpers/utilsStudent';

export const StudentUtils = {
  data() {
    return {
      avatares: ['ardilla.png', 'castor.png', 'conejo.png', 'mapache.png'],
    };
  },
  methods: {
    async studentContructor(studentId, studentObj, institution) {
      try {
        const student = studentObj
          ? studentObj
          : await getItem(`/app-personas/alumno/${studentId}`);
        const { datos_personales, id, colegiatura } = student;

        const fullName = this.getFullName(datos_personales);
        const group = await this.getStudentGroup(student, institution);
        const groupImage = this.getGroupImage(group);

        return {
          id,
          groupId: group.id,
          avatar: groupImage,
          familia: {},
          tuition: colegiatura
            ? numberFormatMX.format(colegiatura.monto)
            : 'Sin asignar',
          ...(fullName && { nombre: fullName }),
          ...(group && group.nombre_grupo && { grupo: group.nombre_grupo }),
          ...student,
        };
      } catch (error) {
        console.log(error);
      }
    },
    getGroupImage(group) {
      if (!group || !group.imagen_grupo) {
        return 'huellita.png';
      }
      return group.imagen_grupo.imagen;
    },
    randomAvatar() {
      const randomIndex = Math.floor(Math.random() * this.avatares.length);
      return this.avatares[randomIndex];
    },
    getFullName(personalData) {
      const {
        primer_nombre,
        segundo_nombre,
        apellido_paterno,
        apellido_materno,
      } = personalData;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },
    async getStudentGroup(student, institution) {
      const group = await fetchGetGroupByStudent(student.id, institution);
      return group.results.length > 0
        ? group.results[0]
        : { nombre_grupo: 'Sin asignar', id: null };
    },
  },
};
